import { useState } from "react";
import { Button } from "@react-md/button";

import Slider from "./slider";
import Support from "./support";
import Options from "./options";
import TextScroller from "./textScroller";

import styles from "./home.module.scss";
import variables from "../../styles/variables.module.scss";

const Homepage = () => {
  return (
    <div
      className={`${styles.container} ${variables.scrollable} ${variables.row}`}
    >
      <TextScroller />
      <div>
        <img src={"./text.svg"} alt="logo" />
      </div>
      <Options />
      <Slider />
      {/* <div className={`${styles.login} ${variables.row}`}>
        <span className={variables.title}>
          If you have an account you can login
        </span>
        <Button className={variables.containedBtn} themeType="contained">
          Sign in
        </Button>
      </div> */}
      <Support />
    </div>
  );
};

export default Homepage;
