import { Button } from "@react-md/button";
import { Form, useTextField } from "@react-md/form";
import { useCallback, useMemo, useState } from "react";
import type { ErrorChangeHandler } from "@react-md/form";
import { TextFieldWithMessage, TextAreaWithMessage } from "@react-md/form";

import api from "../../shared/api";
import { getErrorMessage } from "../profile";

import styles from "./home.module.scss";
import variables from "../../styles/variables.module.scss";

type FieldId = string;
type ErrorRecord = Record<FieldId, boolean | undefined>;

const Support = () => {
  const [support, setSupport] = useState(false);
  const [messageType, setMessageType] = useState<"success" | "error" | null>(
    null
  );

  const [errors, setErrors] = useState<ErrorRecord>({});
  const errored = useMemo(() => Object.values(errors).some(Boolean), [errors]);
  const onErrorChange = useCallback<ErrorChangeHandler>(
    (id, error) => setErrors((prevErrors) => ({ ...prevErrors, [id]: error })),
    []
  );

  const [message, messageProps, { reset, setState }] = useTextField({
    id: "text-area-message",
    required: true,

    minLength: 4,
    getErrorMessage,
    onErrorChange,
  });
  const [email, emailProps, { reset: resetEmail }] = useTextField({
    id: "text-field-email",
    required: true,
    pattern:
      "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
    getErrorMessage,
    onErrorChange,
  });

  const submit = async () => {
    try {
      const { data } = await api.post(`/support`, { email, message });
      setMessageType("success");
      resetEmail();
      reset();
    } catch (err) {
      setMessageType("error");
      console.log(err);
    }
    setTimeout(() => {
      setMessageType(null);
    }, 5000);
  };
  return (
    <>
      {support ? (
        <Form className={variables.row} onSubmit={submit}>
          <span className={variables.title}>For support</span>
          {messageType && (
            <span
              className={variables.textSm}
              style={{ color: messageType === "success" ? "green" : "red" }}
            >
              {messageType === "success"
                ? "Your message was successfully sent"
                : "Something went wrong please try again"}
            </span>
          )}
          <TextFieldWithMessage
            {...emailProps}
            label="Email*"
            className={variables.textXs}
          />
          <TextAreaWithMessage
            {...messageProps}
            label="Message*"
            className={variables.textXs}
            style={{ minHeight: "80px" }}
          />
          <Button
            type="submit"
            className={variables.containedBtn}
            themeType="contained"
            disabled={errored}
          >
            Send
          </Button>
        </Form>
      ) : (
        <span
          className={`${variables.textXs} ${styles.support}`}
          onClick={() => setSupport(true)}
        >
          Support
        </span>
      )}
    </>
  );
};

export default Support;
