import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Configuration } from "@react-md/layout";

import Layout from "./layouts";
import reportWebVitals from "./reportWebVitals";

import "./styles/global.scss";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Configuration>
        <Layout />
      </Configuration>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
