import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import api from "../shared/api";
import AppRouter from "../router";
import Header from "../components/header";
import styles from "./layout.module.scss";
import variables from "../styles/variables.module.scss";

function Layout() {
  const [load, setLoad] = useState(false);
  const location = useLocation();
  let navigate = useNavigate();

  const getUrl = async () => {
    setLoad(true);
    try {
      const { data } = await api.get(`/card/v2${location.pathname}`);
      if (data.externalUrl) {
        window.location.replace(data.externalUrl);
      } else {
        navigate("/");
      }
      setLoad(false);
    } catch (err) {
      console.log(err);
      setLoad(false);
      navigate("/");
    }
  };

  useEffect(() => {
    if (location.pathname.length > 1) {
      getUrl();
    }
  }, [location.pathname]);

  return (
    <div className={`${styles.container} ${load && styles.loader}`}>
      <Header />
      <AppRouter />
      <video autoPlay muted src="./logo.mp4"></video>
      <span className={`${variables.textSmall} ${styles.footer}`}>
        © 2022 Powered by Touch Talk. All Rights Reserved
      </span>
    </div>
  );
}

export default Layout;
