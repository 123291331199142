import { useCallback, useState } from "react";
import { defaultGetErrorMessage, useTextField } from "@react-md/form";
import type { GetErrorMessage } from "@react-md/form";

import Forms from "../forms";
import Passwords from "./passwords";
import { IField, IProfile } from "../settings/types";

import styles from "./profile.module.scss";

export const getErrorMessage: GetErrorMessage = (options) => {
  const { validity, minLength, maxLength } = options;

  if (validity.tooLong) {
    return `No more than ${maxLength} characters.`;
  }

  if (validity.patternMismatch) {
    return `Please enter the data in the specified format.`;
  }

  if (validity.tooShort) {
    return `Must be at least ${minLength} characters.`;
  }

  if (validity.valueMissing) {
    return "This value is required!";
  }

  return defaultGetErrorMessage(options);
};
const Profile = () => {
  const onChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setProfile((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
    },
    []
  );

  const [name, nameProps] = useTextField({
    id: "text-field-name",
    required: true,
    maxLength: 10,
  });
  const [surname, sureNameProps] = useTextField({
    id: "text-field-surname",
    required: true,
    maxLength: 10,
  });
  const [username, usernameProps] = useTextField({
    id: "text-field-username",
    required: true,
    maxLength: 10,
  });

  const [phone, phoneProps] = useTextField({
    id: "text-field-phone",
    required: true,
    pattern: "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{2,6}$",
    onChange,
    getErrorMessage,
  });

  const [email, emailProps] = useTextField({
    id: "text-field-email",
    required: true,
    pattern:
      "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$",
    onChange,
    getErrorMessage,
  });
  const [profile, setProfile] = useState<IProfile>({
    name,
    surname,
    email,
    username,
    phone,
  });

  const fields: IField[] = [
    {
      fieldProps: nameProps,
      name: "name",
      label: "Name",
    },
    {
      fieldProps: sureNameProps,
      name: "surname",
      label: "Surname",
    },
    {
      fieldProps: usernameProps,
      name: "username",
      label: "Username",
    },
    {
      fieldProps: emailProps,
      name: "email",
      label: "Email",
    },
    {
      fieldProps: phoneProps,
      name: "phone",
      label: "Phone Number",
      placeholder: "+37499999999",
    },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.cards}>
        <div className={styles.title}>Profile information</div>
        <Forms fields={fields} className="profile" />
      </div>
      <div className={styles.cards}>
        <div className={styles.title}>Update password</div>
        <Passwords />
      </div>
    </div>
  );
};

export default Profile;
