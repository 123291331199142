import { Carousel } from "react-responsive-carousel";

import variables from "../../styles/variables.module.scss";
import styles from "./home.module.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Slider = () => {
  return (
    <div className={`${styles.slider} ${variables.row}`}>
      <span className={variables.title}>Our partners</span>
      <Carousel autoPlay showThumbs={false} infiniteLoop>
        <div>
          <img src={"./dimac.svg"} alt="Dimac ler" />
        </div>
        <div>
          <img src={"./mek.svg"} alt="Mek" />
        </div>
        <div>
          <img src={"./card.jpg"} alt="logo" />
        </div>
      </Carousel>
    </div>
  );
};

export default Slider;
