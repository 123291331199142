import { useState } from "react";
import { NavLink } from "react-router-dom";
import { FiUser } from "react-icons/fi";
import { AiFillHome } from "react-icons/ai";
import { MdOutlineShoppingCart } from "react-icons/md";

import Popup from "../popup";
import styles from "./header.module.scss";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className={styles.header}>
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.activeClassName : undefined
          }
          to="/"
        >
          <AiFillHome />
        </NavLink>
        {/* <NavLink
          className={({ isActive }) =>
            isActive ? styles.activeClassName : undefined
          }
          to="shop"
        >
          <MdOutlineShoppingCart />
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? styles.activeClassName : undefined
          }
          to="about"
        >
          <FiUser />
        </NavLink> */}
        {/* <img src={"./logo.svg"} alt="logo" onClick={() => setIsOpen(true)} /> */}
      </div>
      {/* {isOpen && <Popup setIsOpen={setIsOpen} />} */}
    </>
  );
};

export default Header;
