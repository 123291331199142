import { Button } from "@react-md/button";
import styles from "./options.module.scss";
import variables from "../../../styles/variables.module.scss";

const Options = () => {
  const titles = [
    "Analyze the packages",
    "Create your Talk version",
    "Order one and make your business available. ",
  ];
  return (
    <div className={`${styles.container} ${variables.row}`}>
      <div className={`${styles.title} ${variables.row}`}>
        <span className={variables.textSm}>Create your NFC</span>
        <span className={variables.textSm}>
          Create your communication style
        </span>
      </div>
      {titles.map((e, index) => (
        <div className={`${styles.option} ${variables.row}`}>
          <span>{index + 1}</span>
          <span className={variables.title}>{e}</span>
        </div>
      ))}
      <Button className={variables.containedBtn} themeType="contained">
        Get started
      </Button>
    </div>
  );
};

export default Options;
