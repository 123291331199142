import { useState } from "react";
import { useSpring, animated } from "react-spring";
import styles from "./home.module.scss";

const TextScroller = () => {
  const [key, setKey] = useState(1);

  const scrolling = useSpring({
    from: { transform: "translate(60%,0)" },
    to: { transform: "translate(-60%,0)" },
    config: { duration: 2000 },
    reset: true,
    reverse: key % 2 == 0,
    onRest: () => {
      setKey(key + 1);
    },
  });

  return (
    <div className={styles.scroller} key={key}>
      <animated.div style={scrolling}>COMING SOON</animated.div>;
    </div>
  );
};

export default TextScroller;
