import { Route, Routes } from "react-router-dom";

import Shop from "./components/shop";
import About from "./components/about";
import Homepage from "./components/homepage";
import Settings from "./components/settings";

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      {/* <Route path="shop" element={<Shop />} /> */}
      {/* <Route path="about" element={<About />} /> */}
      {/* <Route path="settings" element={<Settings />} /> */}
    </Routes>
  );
};

export default AppRouter;
