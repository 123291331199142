import axios from "axios";

export default axios.create({
  baseURL: "https://api.touchtalk.am/",
  headers: {
    "Content-type": "application/json",
    "Cache-Control": "no-cache",
    "Access-Control-Allow-Origin": "*",
  },
});
